.top-main-logo{
    width: 228px;
}
.call-us{
    width: 225px;
    padding: 0px 15px;
    margin-left: 15px;
    border-left: 1px dashed #FF005D;
}
.call-us span{
    font-size: 18px;
    color: #080852;
    border: 2px solid #FF005D;
    padding: 10px;
    border-radius: 100%;
}
.call-us .call-us-heading{
    margin: 0;
    padding-top: 4px;
    line-height: 2;
    font-size: 15px;
    color: #080852;
    font-weight: 300;
}
.call-us h4{
    font-size: 16px;
    color: #fff;
}
nav{
    background: #fff;
}
.request-quote-btn{
    background: transparent;
    border: 2px solid #FF005D;
    color: #080852;
    border-radius: 0px !important;
    font-weight: 600;
    outline: none;
    transition: all 300ms ease-in;
}

.request-quote-btn:hover{
    background-color: #080852;
    border-color: #080852 !important;
    color: #fff;
    transform: scale(1.1);
}