
.feature-item1{
   
    background-image:url('https://i.ibb.co/q1jyj4m/wfx-products.png');
    background-size: cover;
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 50%;
    margin-bottom: 10px;
}
.feature-item1:hover, .feature-item2:hover{
    background: red;
}

.feature-item2{
    background-image:url('https://i.ibb.co/q1jyj4m/wfx-products.png');
    background-size: cover;
    -webkit-transition: all .6s ease-in-out;
    transition: all .6s ease-in-out;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 50%;
}
.feature-ovelay1{
    background: linear-gradient(73.64deg, #080852 0%, rgba(13, 68, 208, 0.77) 48.46%, rgba(89, 0, 143, 0.54) 95.03%),rgba(36,19,73,.3);
    height: 100%;
    font-family: 'poppins',sans-serif;
    align-items: flex-end;
    padding: 50px;
    color: #fff;
    display: flex;
}
.feature-ovelay2{
    background: linear-gradient(73.64deg, #FF005D 0%, rgba(208, 13, 176, 0.77) 48.46%, rgba(89, 0, 143, 0.54) 95.03%),rgba(36,19,73,.3);
    height: 100%;
    font-family: 'poppins',sans-serif;
    align-items: flex-end;
    padding: 50px;
    color: #fff;
    display: flex;
}
.feature-text h2{
    color: #fff;
}
.feature-text p{
    color: #fff;
    font-size: 18px;
}
.hero-title h1{
    background: -webkit-linear-gradient(134deg,#080852, #FF005D);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap; 
    overflow: hidden;
    border-right: .2px solid #080852; 
    animation: 
    typing 4.5s steps(30, end),
    blink-caret .6s step-end infinite;
    width: fit-content;
    margin-bottom: 40px;


}
/* The typing effect */
@keyframes typing {
    from { width: 0 }
    to { width: 58% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #080852 }
  }


  .hero-icon-slider {
    padding-top: 40px;
}
