section.innerpage-wrapper {
    width: 100%;
    text-align: center;
    height: 90vh;
    display: flex;
}
.banner-container {
    width: 50%;
}
.product-container {
    display: flex;
    flex-direction: row;

}
.product-left {
    padding: 100px 50px;
    background: #080852;
    width: 50%;
}
.product2-left{
    padding: 100px 50px;
    background: #FF005D;
    width: 50%;
}
.product-left h2, .product2-left h2 {
    color: #fff;
}
.product-left h5, .product2-left h5 {
    color: #d8d4d4;
    margin-bottom: 20px;
}
.product-left p{
    color: #a9a5a5;
}
.product2-left p{
    color: #fff;
}
.product-right {
    width: 50%;
    text-align: center;
    padding: 50px;
}
.product-right img {
    width: 190px;
    margin-bottom: 40px;
}
.app-icons {
    padding-top: 40px;
}
@media (max-width: 980px) {
    .banner-container {
        width: 100%;
    }
    section.innerpage-wrapper {

        height: 70vh;
    }
    .product-container {
        flex-direction: column;
    }
    .product-left, .product-right, .product2-left{
        width: 100%;
    }
    .product-revers{
        flex-direction: column-reverse;
    }
}