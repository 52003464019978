* {
    font-family: 'poppins',sans-serif;
}
.cta-wrapper{
    background-image: url(./img/cta.jpg);
} 
.innerpage-wrapper{
    background-image: url(./img/header.jpg);
    background-size: cover;
    background-repeat: no-repeat;

}
h2{
    font-family: 'poppins',sans-serif !important;
    font-size: 64px;
    font-weight: 500 !important;
}
h1{
    font-family: 'poppins',sans-serif !important;
    font-size: 80px;
    line-height: 84px;
    font-weight: 500 !important;
}
h3{
    font-family: 'poppins',sans-serif !important;
    font-size: 22px;
    line-height: 28px;
}
h4{
    font-family: 'poppins',sans-serif !important;
}
p{
    font-family: 'poppins',sans-serif !important;
    font-size: 24px;
    color: #000;
}


.innerWidth{
    width: 90%;
    margin: auto;
    /* max-width: 1250px; */
}
.mainflx-container {
    width: 100%;
    display: flex;
}
.flxInner{
    width: 50%;
    height: 90vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.hero-left {
    padding-right: 70px;
    padding-left: 6%;
}

@media (max-width: 980px) {
    .innerWidth{
        width: 90%;
        margin: auto;
        max-width: 1024px;
    }
    .mainflx-container {
        display: block;
        padding-bottom: 50px;
        padding-top: 50px;
    }
    .flxInner{
        width: 100%;
        height: unset;

    }
    .hero-left{
        padding-right: 0px;
        padding-left: 0px;
        padding-bottom: 30px;
    }
    h1{
        font-family: 'poppins',sans-serif !important;
        font-size: 54px;
        line-height: 54px;
    }
    h2{
        font-size: 42px;
        line-height: 48px;
    }
    p{
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .innerWidth{
        width: 90%;
        margin: auto;
        max-width: 767px;
    }
}