.sgrid-wrapper{
    background: #F3F6FC;
    padding-top: 150px 6%;
    text-align: center;
}
.Scard-caontainer {
    width: 100%;
    padding: 50px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    overflow: hidden;
}

.s-card{
    gap:0.6rem;
    text-align: left;
    border-radius: 10px;
    margin: auto;
    transition: all 300ms ease-in-out;
    display: grid;
    padding: 25px;

}
.s-card:hover{
    scale: 1.025;
    cursor: pointer;
    background: linear-gradient(180deg, rgba(255,255,255,0)0%, rgba(136,160,255,0.46) 217.91%);
    box-shadow: 0px 72px 49px -51px rgba(136, 160, 255, 0.21);

}
.scard-image img{
    width: 100%;
    max-width: 5rem;
    padding-bottom: 20px;
}
@media (max-width: 1024px) and (min-width:580px) {

    .Scard-caontainer {
        grid-template-columns: repeat(2, 1fr);
  
    }

}
@media (max-width: 579px){
    .Scard-caontainer {
        grid-template-columns: repeat(1, 1fr);
  
    }
}
@media (max-width: 980px){
.sgrid-wrapper{
 
    padding: 100px 6% 50px 6%;

}
}