.flxInnerleft{
    width: 70%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.flxInnerright{
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 30px;

}
.flx-container{
    display: flex;
}
.cta-title h2 {
    color: #fff;
}
.cta-desc p {
    color: #ccc7c7;
    margin-bottom: 40px;
}
.cta-wrapper{
    padding: 100px 0px;
}
.cta-btn {
    background: transparent;
    border: 2px solid #FF005D;
    color: #fff;
    border-radius: 0px !important;
    font-weight: 400;
    outline: none;
    transition: all 300ms ease-in;
    width: fit-content;
    font-family: 'poppins';
    border-radius: 30px !important;
}
.cta-btn:hover{
    background: #FF005D;
    color: #fff;
}
.cta-profile {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}
.cta-image img {
    border-radius: 50%;
    border: 3px solid #FF005D;
    width: 100%;
}
.cta-image {
    width: 15%;
}

.cta-prof-details span {
    color: #ccc7c7;
    font-size: 22px;
    font-weight: 500;
}
.cta-prof-details p {
    color: #ccc7c7;
    font-size: 16px;
}
@media (max-width: 980px) {
.flx-container {
    display: block;
}
.flxInnerleft {
    width: 100%;
}
.flxInnerright{
    width: 100% !important;
}
}