.innerpage-wrapper{
    display: block !important;
    padding-top: 200px;


}
.wfx-contact-info {
    display: flex;
    width: 80%;
    justify-content: center;
    padding-top: 100px;
    margin: 0px auto;
}
.wfx-contact-box {
    width: 33.3%;
}
.wfx-x-icon img {
    width: 60px;
    margin-bottom: 20px;
}
.wfx-c-title {
    font-size: 22px;
    color: #080852;
}
.form-width {
    width: 60%;
    margin: auto;
    padding-top: 50px;
}

@media (max-width: 980px) {
    .wfx-contact-info {
        display: block;
        width: 90%;
        padding-top: 100px;

    }
    .wfx-contact-box {
        width: 100%;
        margin-bottom: 20px;
    }
    .innerpage-wrapper{
        height: 105vh !important;
        padding-top: 100px;
    
    }
}