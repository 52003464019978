.product-wrapper{
    padding: 100px 6%;
    text-align: center;
}
.swiper-slide.swiper-slide-visible {
    padding: 0px 20px;
    background: #eee;
    border-radius: 10px;
}
  .App{
    padding: 100px 0px;
    text-align: center;
  }
  .slider__image p{
    font-size: 17px;
    color: #000;
  }
  .slider__image h4{
    color: var(--primary);
  }
  .swiper-wrapper{
    align-items: center;
    text-align: left;
  }
  .slider {
    padding: 32px;
    color: #fff;
    width: 90%;
    margin: auto;
  }

  .slider .swiper-container1 {
    width: 100%;
    height: 100%;
  }
  .slider .swiper-container2 {
    width: 100%;
    height: 100%;
  }
  .slider__flex {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: auto;
  }
  .slider__col {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-right: 32px;
  }
  .slider__prev,
  .slider__next {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .slider__prev:focus,
  .slider__next:focus {
    outline: none;
  }
  .slider__thumbs {
    height: calc(500px - 96px);
  }
  .slider__thumbs .slider__image {
    transition: 0.25s;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.5;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .slider__thumbs .slider__image:hover {
    opacity: 1;
  }
  .slider__thumbs .swiper-slide-thumb-active .slider__image {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
  }
  .slider__images {
    height: auto;
    width: 70%;
  }
  .slider__images .slider__image img {
    transition: 3s;
  }
  .slider__images .slider__image:hover img {
    transform: scale(1.1);
  }
  .slider__image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .slider__image img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .slider .swiper-container2 .slider__image {
    width: 48%;
    height: auto;
    margin: auto;
}
.slide-content-wrap {
    text-align: center;
    width: 70%;
    margin: auto;
}
.slider-cta-container {
    width: 480px;
    display: flex;
    margin: auto;
    justify-content: center;
}
.slider-cta1 {
    width: 50%;
}
.slider-cta2 {
    width: 50%;
}
.slider-cta1 img{
    height: 88px;
}
.slider-cta2 img{
    height: 88px;
}
  @media (max-width: 767.98px) {
    .slider__flex {
      flex-direction: column-reverse;
    }
    .slider__col {
      flex-direction: row;
      align-items: center;
      margin-right: 0;
      margin-top: 24px;
      width: 100%;
    }
    .slider__images {
      width: 100%;
    }
    .slider__thumbs {
      height: 100px;
      width: calc(100% - 96px);
      margin: 0 16px;
    }
    .slider__prev,
    .slider__next {
      height: auto;
      width: 32px;
    }
  }

  @media (max-width: 767.98px){
  .slider-cta-container{
    width: 200px;
  }
  .slider .swiper-container2 .slider__image{
    width: 90%;
  }
  .slide-content-wrap{
    width: 90%;
  }
  .slider__col {
    display: none;
}
.App{
  padding: 100px 0px 50px 0px;
  text-align: center;
}
}